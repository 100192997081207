import Vue from 'vue';
import {store} from '../store'
import Router from 'vue-router';

import Home from '../components/Home'
// import Hintergrund from '../components/Hintergrund'
import Impressum from '../components/Impressum'
import Datenschutz from '../components/Datenschutz'
import Login from '../components/Login'
import PasswordReset from '../components/PasswordReset'
import SignIn from '../components/SignIn'
import EMailVerification from "../components/EmailVerification"

import AppHome from '../components/app/AppHome'
import Archive from '../components/app/Archive'
import Breeds from '../components/app/Breeds'
import Konto from '../components/app/Konto'
import Mitteilungen from '../components/app/Mitteilungen'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { 
          path: '*',
          redirect: '/home' 
        },
        { 
          path: '/', 
          redirect: '/home' 
        },
        {
          path: '/home',
          name: 'home',
          component: Home,
        },
        {
          path: '/login',
          name: 'login',
          component: Login,
        },
        {
          path: '/impressum',
          name: 'impressum',
          component: Impressum,
        },
        {
          path: '/datenschutz',
          name: 'datenschutz',
          component: Datenschutz,
        },
        {
          path: '/passwordreset',
          name: 'passwordreset',
          component: PasswordReset,
        },
        {
          path: '/signin',
          name: 'signin',
          component: SignIn,
        },
        {
          path: '/email',
          name: 'email',
          component: EMailVerification,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/app',
          name: 'app',
          component: AppHome,
          meta: {
              requiresAuth: true,
              requiresEMailVerifi: true
            }
        },
        {
          path: '/app/archive',
          name: 'archive',
          component: Archive,
          meta: {
              requiresAuth: true,
              requiresEMailVerifi: true
            }
        },
        {
          path: '/app/breeds',
          name: 'breeds',
          component: Breeds,
          meta: {
              requiresAuth: true,
              requiresEMailVerifi: true
            }
        },
        {
          path: '/app/konto',
          name: 'konto',
          component: Konto,
          meta: {
              requiresAuth: true,
              requiresEMailVerifi: true
            }
        },
        {
          path: '/app/mitteilungen',
          name: 'mitteilungen',
          component: Mitteilungen,
          meta: {
              requiresAuth: true,
              requiresEMailVerifi: true
            }
        },
      ]
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresEMailVerifi = to.matched.some(record => record.meta.requiresEMailVerifi)
  const currentUser = store.getters.user.loggedIn
  const emailVerified = store.getters.user.data.emailVerified
  console.log('user in store', emailVerified)

  if (to.name === 'login' && currentUser && emailVerified) next({ name: 'breeds' })
  else if (requiresAuth && !currentUser) next({ name: 'login' })
  else if (requiresAuth && currentUser && requiresEMailVerifi && !emailVerified) next({ name: 'email' })
  else next()
})

export default router