<template>
  <div>
    <section class="bg-primary pt-5 pb-5">
      <b-container>
        <b-row >
          <b-col class="d-flex align-items-center">
            <div>
              <h3 class="mb-3">BreedingApp</h3>
              <p>Diese App unterstützt dich bei der Brut deiner gefiederten Freunde. Sie dient der Organisation deiner Bruten und erinnert dich aktiv an den bevorstehenden Schlupf.</p>
                <a href="https://apps.apple.com/us/app/breedingapp/id1591476329" name="Appstore"><img alt="AppStore" src="../assets/appstore.png" class="appstoreImage"/></a>
                <a href="https://play.google.com/store/apps/details?id=com.breedingapp&hl=de" name="Googlr Play"><img alt="Google Play" src="../assets/playstore.png" class="appstoreImage"/></a>
                <a href="login" name="Appstore"><img alt="AppStore" src="../assets/web.png" class="appstoreImage"/></a>
            </div>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <img style="max-height: 80vh;" src="../assets/mockup.png">
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="pt-5 pb-5">
      <b-container>
        <b-row>
          <b-col>
            <p>Derzeitige Funktionen der App: </p>
            <b-list-group>
              <b-list-group-item>Anlegen von Bruten</b-list-group-item>
              <b-list-group-item>Übersichtliche auflistung aller Bruten</b-list-group-item>
              <b-list-group-item>Aktive benachrichtigung über den Status der Brut (nur Mibile Apps)</b-list-group-item>
              <b-list-group-item>Archivierung aller vergangenen Bruten</b-list-group-item>
            </b-list-group>

            <br><br>
            <p>Wünsche und Anregungen zur App dürfen jederzeit an <b>feedback@brutapp.eu</b> gerichtet werden.</p>
            <p>Fehler und Funktionsstörungen bitte in der App melden oder an <b>bug@brutapp.eu</b></p>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<!-- Add "scoped" attribute to b-list-group-itemmit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  b-list-group-item {
    display: inb-list-group-itemne-block;
    margin: 0 10px;
  }
  .appstoreImage {
    max-height: 50px;
    cursor: pointer;
    margin: 5px;
  }
  .stores{
    max-width: 100%;
  }
</style>
