<template>
  <div>
    <div id="footer">
      <p>- Maximilian Lembke -</p>
      <router-link to="/datenschutz">Datenschutz</router-link>
      <router-link to="/impressum">Impressum</router-link>
    </div>
    <div v-if="user.loggedIn">
      <b-container>
        <small>
          <a href="https://icons8.com">Einige Icons sind von Icons8</a>
          <a href="https://fontawesome.com">Einige Icons sind von Fontawsome</a>
        </small>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapGetters([
      'user',
    ])
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  margin: 0 20px;
}
#footer {
  border-top: 1px #dee2e6 solid;
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
