<template>
  <div class="hello">
    <b-container>
      <b-row>
        <b-col>
          <h1>Impressum</h1>

          <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p>Maximilian Lembke<br />
          Kragstedt 4<br />
          24997 Wanderup</p>

          <h2>Kontakt</h2>
          <p>Telefon: 017641680069<br />
          E-Mail: info@brutapp.eu</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Rechtliches',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
