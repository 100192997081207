<template>
  <div id="app" style="margin-top: 62px;">
      <Navbar style="z-index: 1000; width: 100%;"></Navbar>
      <router-view></router-view>
      <Footer/>
      <Alert />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Alert from "./components/Alert";
import Footer from "./components/Footer"

export default {
  name: 'App',
  components: {
    Navbar,
    Alert,
    Footer
  }
}
</script>

<style lang="scss">
  $theme-colors: (
    "primary": #5cbda3,
  );

  @each $color, $value in $theme-colors {
    @if ((lightness($value)) < 90 and (hue($value)) > 5) {
      .btn-#{$color} {
        @extend .text-light
      };
      .bg-#{$color} {
        @extend .text-light
      };
    };
  };

  @import '~bootstrap/scss/bootstrap.scss';
  @import '~bootstrap-vue/src/index.scss';

</style>
