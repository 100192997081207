<template>
  <!-- <div class="d-flex justify-content-center align-items-center" style="height: calc(100vh - 164px)"> -->
   <b-container>
     <b-row>
    <b-col>
      <h1>Konto</h1><br>
    </b-col>
    <b-col>
      <p>Dieser Bereich ist in arbeit.</p>
    </b-col>
     </b-row>
   </b-container>
    <!-- </div> -->
</template>

<script>


export default {
  name: 'Konto',
  data(){
    return{
      show: true,

    }
  },
  methods:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}
.btn-primary{
  background-color: #5cbda3;
  border-color: #5cbda3;
    font-weight: 500;
}
.btn-primary:hover, .btn-primary:disabled, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
  background-color: #48947f;
  border-color: #48947f;
}
.btn-link{
  color: #5cbda3;
    font-weight: 500;
}
.btn-link:hover, .btn-link:disabled, .btn-link:not(:disabled):not(.disabled):active, .btn-link:not(:disabled):not(.disabled).active, .show > .btn-link.dropdown-toggle{
  color: #48947f;

}

.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
</style>
