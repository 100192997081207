import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database"
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyAMmrzUmgopTcGtbUu155i3j8r1PLqu_VA",
  authDomain: "breeding-app-ml-13.firebaseapp.com",
  databaseURL: "https://breeding-app-ml-13-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "breeding-app-ml-13",
  storageBucket: "breeding-app-ml-13.appspot.com",
  messagingSenderId: "731328530483",
  appId: "1:731328530483:web:dc4db5fc54514d36a6bb46",
};

if (process.env.NODE_ENV !== 'production') {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const firebase = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider('6LeuLPQfAAAAADmVQJ0iiqWJPaI0FZ23IEEPsxly'),
  isTokenAutoRefreshEnabled: true
});

const auth = getAuth(firebase);
const firestore = getFirestore(firebase);
const db = getDatabase(firebase);

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  connectFirestoreEmulator(firestore, 'localhost', 9001);
  connectDatabaseEmulator(db, "localhost", 9000);
}

export { firestore, auth, appCheck};

