import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    alert: {
      kind: 'danger',
      message: '',
      time: false
    }
  },
  getters: {
    user(state){
      return state.user
    },
    alert(state){
      return state.alert
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_ALERT(state, value) {
      state.alert = value;
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user ? user : false);
      if (user) {
        commit("SET_USER", user ? user : false);
      } else {
        commit("SET_USER", false);
      }
    },
    fetchAlert({ commit }, alert) {
      alert.time = alert?.time || true
      commit("SET_ALERT", alert);
    }
  }
});

export { store }