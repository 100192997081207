<template>
  <div class="fixed-top" style="background-color: white;">
    <div class="d-xs d-md-none">
      <b-navbar toggleable type="light" variant="light" style="padding-right: 25px;">
        <b-navbar-brand href="#"><img alt="BrutApp Logo" class="logo-nav-mobile" src="../assets/logo.png"></b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse">
          <template #default="{ expanded }" >
            <b-icon v-if="expanded" icon="list-nested"></b-icon>
            <b-icon v-else icon="list"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
          <router-link to="/home"><b-nav-item to="/home" >Home</b-nav-item></router-link>
          <!-- <router-link to="/hintergrund"><b-nav-item to="/hintergrund">Hintergrund</b-nav-item></router-link> -->
          <router-link to="/app" v-if="!user.loggedIn"><b-nav-item to="/app">Web-App</b-nav-item></router-link>
          <router-link v-if="user.loggedIn" to="/app/breeds"><b-nav-item to="/app/breeds"><font-awesome-icon icon="fa-solid fa-egg" /> Bruten</b-nav-item></router-link>
          <router-link v-if="user.loggedIn" to="/app/archive"><b-nav-item to="/app/archive"><font-awesome-icon icon="fa-solid fa-box-archive" /> Archive</b-nav-item></router-link>
          <router-link v-if="user.loggedIn" to="/app/konto"><b-nav-item to="/app/konto"><font-awesome-icon icon="fa-solid fa-user" /> Konto</b-nav-item></router-link>
          <router-link v-if="user.loggedIn" to="/app/mitteilungen"><b-nav-item to="/app/mitteilungen"><font-awesome-icon icon="fa-solid fa-paper-plane" /> Mitteilungen</b-nav-item></router-link>
          <b-nav-item v-if="user.loggedIn" to="#"  @click="logout()"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" /> Abmelden</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div class="d-none d-md-block" style="padding-top: 20px; widht: 100%;">
      <div>
        <b-nav tabs align="center">
          <b-navbar-brand href="#" style="margin-bottom: 0px;">
            <img alt="BrutApp Logo" class="logo-nav" src="../assets/logo.png">
          </b-navbar-brand>
          <router-link to="/home"><b-nav-item to="/home" >Home</b-nav-item></router-link>
          <!-- <router-link to="/hintergrund"><b-nav-item to="/hintergrund">Hintergrund</b-nav-item></router-link>-->
          <router-link v-if="!user.loggedIn" to="/app"><b-nav-item to="/app">Web-App</b-nav-item></router-link>
          <router-link v-if="user.loggedIn" to="/app/breeds"><b-nav-item to="/app/breeds"><font-awesome-icon icon="fa-solid fa-egg" /> Bruten</b-nav-item></router-link>
          <router-link v-if="user.loggedIn" to="/app/archive"><b-nav-item to="/app/archive"><font-awesome-icon icon="fa-solid fa-box-archive" /> Archive</b-nav-item></router-link>
          <b-nav-item v-if="user.loggedIn" @click="showModal"><font-awesome-icon icon="fa-solid fa-paper-plane" /> Mitteilungen <b-badge v-show="messages.length > 0" style="position: relative; top: -10px; right: 5px;" variant="danger" pill>{{messages.length}}</b-badge></b-nav-item>
          <router-link to="/konto" v-if="user.loggedIn"><b-nav-item to="/app/konto"><font-awesome-icon icon="fa-solid fa-user" /> Konto</b-nav-item></router-link>
          <b-nav-item v-if="user.loggedIn"  @click="logout()"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" /> Abmelden</b-nav-item>
        </b-nav>
      </div>
    </div>
    <b-modal id="mitteilungen" ref="mitteilungen" header-bg-variant="primary" header-text-variant="light" hide-footer title="Mitteilungen">
      <Mitteilungen :messages="messages" @reloadMessages="loadMessages()"/>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { store } from '../store'
import { getAuth, signOut } from "firebase/auth";
import Mitteilungen from "./app/Mitteilungen.vue"
import { getDatabase, ref, orderByChild, query, get } from "firebase/database";

export default {
  name: 'Navbar',
  computed: {
    ...mapGetters([
      'user',
    ])
  },
  components:{
    Mitteilungen
  },
  data(){
    return{
      messages: []
    }
  },
  methods:{
    showModal() {
      this.$refs['mitteilungen'].show()
    },
    logout(){
      signOut(getAuth()).then(() => {
        store.dispatch("fetchAlert", {
          kind: 'success',
          time: 3,
          message: 'Abgemeldet'
        })
        this.$router.push('home')
      }).catch((error) => {
        store.dispatch("fetchAlert", {
          kind: 'danger',
          message: error.message
        })
      });
    },
    async loadMessages(){
      this.messages = []
      const breedsQuery = await get(query(ref(getDatabase(), `/home/users/${store.state.user.data.uid}`), orderByChild('stamp')))
      breedsQuery.forEach(each=>{
        let messagesData = each.val()
        messagesData.id = each.key
        this.messages.push(messagesData)
      })
    },
  },
  mounted(){
   store.state.user.data.uid && this.loadMessages()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
a {
  color: #42b983;
  text-decoration: none !important;
}
.logo-nav{
  position: relative;
  padding: 0;
  margin: 0;
  height: 40px;
}
.logo-nav-mobile{
  position: relative;
  left: 10px;
  padding: 0;
  height: 40px;
}
.nav-item a, #rechtliches li a{
  color: #5cbda3;
}
.nav-item a:hover, #rechtliches li a:hover{
  background-color: #5cbda3 !important;
  border-color: #5cbda3;
  color: #ffffff;
}
.router-link-active li a{
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff !important;
}
.router-link-active li:hover a{
  background-color: #5cbda3 !important;
  border-color: #5cbda3 !important;
  color: #ffffff !important;
}
.navbar-brand {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>
