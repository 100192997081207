<template>
  <div>
    <b-card v-if="messages.length <= 0">Sie haben derzeit keine Nachrichten</b-card>
    <template v-else>
      <b-card v-for="(message, index) in messages" :key="index">
        <font-awesome-icon class="fixed" icon="fa-solid fa-trash"  @click="deleteMessage(message.id)"/>
        <b class="pb-1">{{message.title}}</b><br>
        <small>{{returnDate(message.stamp)}}</small><br>
        {{message.message}}
      </b-card>
    </template>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { getDatabase, ref, remove } from "firebase/database";
import { store } from '../../store'

export default {
  name: 'Mitteilungen',
  props:{
    messages: Array,
  },
    methods:{
    returnDate(date){
      return moment(date, 'x').format('DD.MM.yyyy')
    },
    deleteMessage(id){
      remove(ref(getDatabase(), `/home/users/${store.state.user.data.uid}/${id}`))
      this.$emit('reloadMessages')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}
.btn-primary{
  background-color: #5cbda3;
  border-color: #5cbda3;
    font-weight: 500;
}
.btn-primary:hover, .btn-primary:disabled, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
  background-color: #48947f;
  border-color: #48947f;
}
.btn-link{
  color: #5cbda3;
    font-weight: 500;
}
.btn-link:hover, .btn-link:disabled, .btn-link:not(:disabled):not(.disabled):active, .btn-link:not(:disabled):not(.disabled).active, .show > .btn-link.dropdown-toggle{
  color: #48947f;

}

.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
.fixed {
  position: absolute;
  right: 5px; 
  top: 5px;
}
</style>
