<template>
  <div>
    <b-card header="Neue Brut">
      <b-form @submit.prevent="onSubmit">

        <b-form-group
          id="input-group-1"
          label="Titel"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.title"
            type="text"
            placeholder="Titel"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Notizen"
          label-for="notes"
        >
          <b-form-textarea
            id="notes"
            v-model="form.note"
            placeholder="Notizen ..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          label="Tierart"
          label-for="selectedBreed"
        >
          <b-form-select v-model="form.selected" id="selectedBreed" :options="options"></b-form-select>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          label="Startdatum"
          label-for="date"
        >
          <b-form-datepicker v-model="form.date" id="date" :min="new Date()" :max="brutende" locale="de-DE" v-bind="labels"></b-form-datepicker>
          
        </b-form-group>

        <b-button type="submit" class="w-100" variant="primary">Erstellen</b-button>
        <b-button @click="$emit('closeCreateBreed')" class="w-100 mt-3" variant="danger">Abbrechen</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { getFirestore, collection, query, orderBy, getDocs } from "firebase/firestore";
import { getDatabase, ref, push } from "firebase/database";
import moment from 'moment-timezone'
import {store} from '../../store'

export default {
  name: 'Breeds',
  components:{

  },
  data(){
    return{
      form: {
        date: new Date(),
        note: '',

      },
      options: [],
      labels: {
        labelPrevDecade: 'Vorheriges Jahrzehnt',
        labelPrevYear: 'Vorheriges Jahr',
        labelPrevMonth: 'Vorheriger Monat',
        labelCurrentMonth: 'Aktueller Monat',
        labelNextMonth: 'Nächster Monat',
        labelNextYear: 'Nächstes Jahr',
        labelNextDecade: 'Nächstes Jahrzehnt',
        labelToday: 'Heute',
        labelSelected: 'Ausgewähltes Datum',
        labelNoDateSelected: 'Kein Datum gewählt',
        labelCalendar: 'Kalender',
        labelNav: 'Kalendernavigation',
        labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
      },
      brutarten: {}
    }
  },
  computed: {
    brutende(){
      let toAdd = this.brutarten?.[this.form?.selected]?.Dauer
      let start = moment(new Date(), "DD.MM.YYYY").endOf('day')
      return this.form?.selected ? start.add(toAdd, 'days').add(1, 'years').toDate() : new Date()
    }
  },
  methods:{
    async loadBreeds(){
      const q = query(collection(getFirestore(), "brutarten"), orderBy('name'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.brutarten[doc.id] = doc.data()
        this.options.push({value: doc.id, text: doc.data().name})
      });
    },
    onSubmit(){
      let toAdd = this.brutarten?.[this.form?.selected]?.Dauer
      let Data = {
        title: this.form.title,
        notiz: this.form.note,
        date: moment(this.form.date).endOf('day').format('x'),
        end: moment(this.form.date).add(toAdd, 'days').endOf('day').format('x'),
        brutart: this.form.selected
      }
      push(ref(getDatabase(), 'breeds/' + this.$store.state.user.data.uid + '/'), Data)
      .then(d=>{
        const title = this.form.title
        console.log(d)
         store.dispatch("fetchAlert", {
          kind: 'success',
          time: 3,
          message: `Ihre Brut ${title} wurde erfolgreich erstellt.`
        })
        this.$emit('reloadData')
        this.$emit('closeCreateBreed')
      })
      .catch(err=>{
         store.dispatch("fetchAlert", {
          kind: 'danger',
          message: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es Später erneut.'
        })
        console.log(err)
      })
    }
  },
  mounted(){
    this.loadBreeds()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}
.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: #f8f8f8;
}
.big {
  font-size: 4rem;

}
</style>
