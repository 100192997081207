<template>
  <div class="pt-5 pb-5">
      <b-container v-if="breeds.length > 0">
        <b-row>
          <b-col md="4">
            <h3>Meine vergangenen Bruten</h3>
            <b-list-group>
              <b-list-group-item v-for="breed in breeds" :key="breed.key" class="clickable" @click="selected=breed.key">
                {{breed.title}}<br>
                {{formatDate(breed.date)}} - {{formatDate(breed.end)}}<br>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col md="8 text-center">
            <h1>{{selectedBreed.title}}</h1>
            {{formatDate(selectedBreed.date)}} - {{formatDate(selectedBreed.end)}}<br>
            <div class="m-5">
              <p>{{selectedBreed.notiz}}</p>
            </div>
            <hr>
            <h4>Die Schritte</h4>
              <Timeline v-if="selectedBreedArt.schluesselMomente">
                <TimelineItem bg-color="#5cbda3" v-for="(schluesselmoment, index) in orderMyData(selectedBreedArt.schluesselMomente)" :key="index">
                  <h4>{{schluesselmoment.name}}</h4>
                  {{dateOf(selectedBreed.date, schluesselmoment.Dauer)}}
                  <p class="text-left">{{schluesselmoment.description}}</p>
                </TimelineItem>
              </Timeline>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-else>
        <b-col>
          <p>Sie haben derzeit keine Bruten im Archiv. Hier werden Bruten angezeigt, die Beendet sind.</p>
        </b-col>
      </b-container>
  </div>
</template>

<script>
import { getDatabase, ref, endAt, orderByChild, query, get } from "firebase/database";
import { getFirestore, getDoc, doc } from "firebase/firestore"; 
import moment from 'moment-timezone'
import {store} from '../../store'
import { Timeline, TimelineItem } from 'vue-cute-timeline'
import 'vue-cute-timeline/dist/index.css'

export default {
  name: 'Breeds',
  components:{
    Timeline,
    TimelineItem
  },
  data(){
    return{
      breeds: [],
      selected: null,
      breedData: null,
      selectedBreedArt: false
    }
  },
  computed: {
    // a computed getter
    selectedBreed() {
      const brutart = this.breeds.find(x => x.key === this.selected)
      this.getBreedData(brutart)
      return brutart
    }
  },
  methods:{
    async loadBreeds(){
      const timestamp = moment().format('x')
      const breedsQuery = await get(query(ref(getDatabase(), `/breeds/${store.state.user.data.uid}`), orderByChild('end'), endAt(timestamp)))
      console.log(breedsQuery.exists())
      breedsQuery.forEach(each=>{
        this.selected = this.selected || each.key
        const thisBreed = each.val()
        thisBreed.key = each.key
        this.breeds.push(thisBreed)
      })
    },
    tageBisZumSchlupf(breedEnd){
      let start = moment(new Date())
      let end = moment(breedEnd, 'x')
      return end.diff(start, 'days') 
    },
    formatDate(date){
      return moment(date, 'x').format("DD.MM.YYYY")
    },
    async getBreedData(brutart){
      const breedsRef = doc(getFirestore(), "brutarten", brutart.brutart)
      const docSnap = await getDoc(breedsRef)
      if (!docSnap.empty) {
        this.selectedBreedArt = docSnap.data()
      } else {
        store.dispatch("fetchAlert", {
          kind: 'danger',
          message: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es Später erneut.'
        })
      }
    },
    orderMyData(object){
      const compare = ( a, b )=>{
        if ( a.Dauer < b.Dauer ){
          return -1;
        }
        if ( a.Dauer > b.Dauer ){
          return 1;
        }
        return 0;
      }
      return Object.values(object).sort( compare )
    },
    returnDays(start, add){
      if(!start || !add){
        return 0
      }
      const theMoment = moment(start, 'x').add(add, 'days')
      return theMoment.diff(new Date(), 'days')
    },
    dateOf(date, days){
      return moment(date,'x').add(days, 'days').format("DD.MM.YYYY")
    }
  },
  mounted(){
    this.loadBreeds()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}
.btn-primary{
  background-color: #5cbda3;
  border-color: #5cbda3;
    font-weight: 500;
}
.btn-primary:hover, .btn-primary:disabled, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
  background-color: #48947f;
  border-color: #48947f;
}
.btn-link{
  color: #5cbda3;
    font-weight: 500;
}
.btn-link:hover, .btn-link:disabled, .btn-link:not(:disabled):not(.disabled):active, .btn-link:not(:disabled):not(.disabled).active, .show > .btn-link.dropdown-toggle{
  color: #48947f;

}
.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: #f8f8f8;
}
.big {
  font-size: 4rem;

}
</style>
