<template>
  <div class="d-flex justify-content-center align-items-center" style="height: calc(100vh - 164px)">
      <b-col style="max-width: 450px;">
        <b-card header="Registrieren" >
          <b-form @submit.prevent="onSubmit" v-if="show">

            <b-form-group
              id="input-group-1"
              label="Name"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.name"
                type="text"
                placeholder="Max Mustermann"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="E-Mail-Adresse:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="E-Mail-Adresse"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Password:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.password"
                type="password"
                placeholder="***"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-checkbox v-model="checked" name="check-button" class="mb-3" switch>
              Ich habe <a href="datenschutz" target="_blank"> Datenschutzbedingungen</a> gelesen.
            </b-form-checkbox>

            <b-button type="submit" class="w-100" variant="primary">Registrieren</b-button>
          </b-form>
          <div class="spaceBetween mt-3">
            <b-button variant="link" @click="$router.push('login')">Anmelden</b-button>
            <b-button variant="link" @click="$router.push('passwordreset')">Passwort vergessen?</b-button>
          </div>
        </b-card>
      </b-col>
  </div>
</template>

<script>
import {createUserWithEmailAndPassword, getAuth, sendEmailVerification, updateProfile} from "firebase/auth"
import {store} from "../store"


export default {
  name: 'signIn',
  data(){
    return{
      show: true,
      form: {},
      checked: false
    }
  },
  methods:{
    onSubmit(){
      if( !this.checked){
        store.dispatch("fetchAlert", {
          kind: 'danger',
          message: 'Sie müssen die Datenschutzbestimungen akzeptieren.'
        })
      } else {
        createUserWithEmailAndPassword(getAuth(), this.form.email, this.form.password)
          .then(async (userCredential) => {
            store.dispatch("fetchUser", userCredential.user)
            await sendEmailVerification(getAuth().currentUser)
            await updateProfile(getAuth().currentUser,{
              displayName: this.form.name
            })

            store.dispatch("fetchAlert", {
              kind: 'success',
              message: 'Sie erhalten in den nächsten Minuten eine E-Mail zum Bestätigen Ihrer E-Mail-Adresse.'
            })
            this.$router.push('email')
          })
          .catch((error) => {
            store.dispatch("fetchAlert", {
              kind: 'danger',
              message: error.message
            })
            console.log(error)
          });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}

.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
</style>
