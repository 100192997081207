<template>
  <div class="d-flex justify-content-center align-items-center" style="height: calc(100vh - 164px)">
      <b-col style="max-width: 450px;">
        <b-card header="Anmelden">
          <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">

            <b-form-group
              id="input-group-1"
              label="E-Mail-Adresse:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="E-Mail-Adresse"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Password:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.password"
                type="password"
                placeholder="***"
                required
              ></b-form-input>
            </b-form-group>

            <b-button type="submit" class="w-100" variant="primary">Anmelden</b-button>
          </b-form>
          <div class="spaceBetween mt-3">
            <b-button variant="link" @click="$router.push('signin')">Registrieren</b-button>
            <b-button variant="link" @click="$router.push('passwordreset')">Passwort vergessen?</b-button>
          </div>
        </b-card>
      </b-col>
  </div>
</template>

<script>
import {getAuth, signInWithEmailAndPassword} from "firebase/auth"
import {store} from '../store'

export default {
  name: 'Login',
  data(){
    return{
      show: true,
      form: {}
    }
  },
  methods:{
    onSubmit(){
    signInWithEmailAndPassword(getAuth(), this.form.email, this.form.password)
      .then(() => {
        this.$router.push('app/breeds')
      })
      .catch((error) => {
        store.dispatch("fetchAlert", {
          kind: 'danger',
          message: error.message
        })
      });
    },
    onReset(){
      alert('Reset')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}
.btn-primary{
  background-color: #5cbda3;
  border-color: #5cbda3;
    font-weight: 500;
}
.btn-primary:hover, .btn-primary:disabled, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
  background-color: #48947f;
  border-color: #48947f;
}
.btn-link{
  color: #5cbda3;
    font-weight: 500;
}
.btn-link:hover, .btn-link:disabled, .btn-link:not(:disabled):not(.disabled):active, .btn-link:not(:disabled):not(.disabled).active, .show > .btn-link.dropdown-toggle{
  color: #48947f;
}

.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
</style>
