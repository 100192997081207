<template>
  <div class="d-flex justify-content-center align-items-center" style="height: calc(100vh - 164px)">
      <b-col style="max-width: 450px;">
        <b-card header="Passwort zurücksetzen">
          <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">

            <b-form-group
              id="input-group-1"
              label="E-Mail-Adresse:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="E-Mail-Adresse"
                required
              ></b-form-input>
            </b-form-group>

            <b-button type="submit" class="w-100" variant="primary">Passwort Reset</b-button>
          </b-form>
          <div class="spaceBetween mt-3">
            <b-button variant="link" @click="$router.push('login')">Anmelden</b-button>
            <b-button variant="link" @click="$router.push('signin')">Registrieren</b-button>
          </div>
        </b-card>
      </b-col>
  </div>
</template>

<script>
import {getAuth, sendPasswordResetEmail} from "firebase/auth"
import {store} from "../store"

export default {
  name: 'passwordreset',
  data(){
    return{
      show: true,
      form: {}
    }
  },
  methods:{
    onSubmit(){
    sendPasswordResetEmail(getAuth(), this.form.email)
      .then(() => {
        store.dispatch("fetchAlert", {
          kind: 'success',
          message: 'Sie erhalten in den nächsten Minuten eine E-Mail um ein neues Passwort zu vergeben.'
        })
        this.$router.push('login')
      })
      .catch((error) => {
        store.dispatch("fetchAlert", {
          kind: 'danger',
          time: 10,
          message: error.message
        })
      });
    },
    onReset(){
      alert('Reset')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}
.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
</style>
