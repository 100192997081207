<template>
  <div class=" pt-5 pb-5">
    <template v-if="showAddBreed">
      <b-container>
        <b-col>
          <AddBreed @closeCreateBreed="showAddBreed = !showAddBreed" @reloadData="loadBreeds()"/>
        </b-col>
      </b-container>
    </template>
    <template v-else-if="showEditBreed">
      <b-container>
        <b-col>
          <EditBreed @closeEditBreed="showEditBreed = !showEditBreed" @reloadBreed="loadBreeds()" :title="selectedBreed.title" :notes="selectedBreed.notiz" :id="selected"/>
        </b-col>
      </b-container>
    </template>
    <template v-else>
      <b-container v-if="breeds.length > 0">
        <b-row>
          <b-col md="4">
            <h3>Meine Bruten</h3>
            <b-list-group>
              <b-list-group-item class="text-center clickable" @click="showAddBreed = true"><font-awesome-icon  icon="fa-solid fa-plus" /><br>Neue Brut erstellen</b-list-group-item>
              <b-list-group-item v-for="breed in breeds" :key="breed.key" class="clickable" @click="selected=breed.key">
                {{breed.title}}<br>
                {{formatDate(breed.date)}} - {{formatDate(breed.end)}}<br>
                {{tageBisZumSchlupf(breed.end)}} Tage bis zum Schlupf
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col md="8 text-center">
            <div class="d-flex justify-content-end">
              <font-awesome-icon @click="showEditBreed = true" class="clickable" icon="fa-solid fa-pen-to-square" />
            </div>
            <h1>{{selectedBreed.title}}</h1>
            <span class="big">{{tageBisZumSchlupf(selectedBreed.end)}}</span><br> Tage bis zum Schlupf<br>
            {{formatDate(selectedBreed.date)}} - {{formatDate(selectedBreed.end)}}<br>
            <div class="m-5">
              <p>{{selectedBreed.notiz}}</p>
            </div>
            <div>
              <b-row>
                <b-col>                  
                  <icons8 :id="selectedBreedArt.name" />
                  <p class="mt-3">{{selectedBreedArt.name}}</p>
                </b-col>
                <b-col>
                  <font-awesome-icon icon="fa-solid fa-calendar-days"  size="4x" />
                  <p class="mt-3">{{selectedBreedArt.Dauer}}</p>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col>                  
                  <font-awesome-icon icon="fa-solid fa-temperature-half"  size="4x"  />
                  <p class="mt-3">{{selectedBreedArt.Temp}}</p>
                </b-col>
                <b-col>
                  <font-awesome-icon icon="fa-solid fa-droplet"  size="4x"  />
                  <p class="mt-3">{{selectedBreedArt.Feuchtigkeit}}</p>
                </b-col>
              </b-row>
            </div>
            <hr>
            <h4>Die nächsten Schritte</h4>
              <Timeline v-if="selectedBreedArt.schluesselMomente">
                <TimelineItem bg-color="#5cbda3" v-for="(schluesselmoment, index) in orderMyData(selectedBreedArt.schluesselMomente)" v-show="returnDays(selectedBreed.date, schluesselmoment.Dauer) >= 0" :key="index">
                  <h4>{{schluesselmoment.name}}</h4>
                  <template v-if="returnDays(selectedBreed.date, schluesselmoment.Dauer) > 0">in {{returnDays(selectedBreed.date, schluesselmoment.Dauer)}} Tagen</template>
                  <template v-else-if="returnDays(selectedBreed.date, schluesselmoment.Dauer) == 0">Jetzt</template>
                  <p class="text-left">{{schluesselmoment.description}}</p>
                </TimelineItem>
              </Timeline>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-else>
        <b-col class="text-center">
          <p>Sie haben derzeit keine Bruten.</p>
          <b-button variant="primary" @click="showAddBreed = true"><font-awesome-icon icon="fa-solid fa-plus" /> Eine Brut erstellen</b-button>
        </b-col>
      </b-container>
    </template>
  </div>
</template>

<script>
import { getDatabase, ref, startAt, orderByChild, query, get } from "firebase/database";
import { getFirestore, getDoc, doc } from "firebase/firestore"; 
import moment from 'moment-timezone'
import {store} from '../../store'
import { Timeline, TimelineItem } from 'vue-cute-timeline'
import 'vue-cute-timeline/dist/index.css'
import AddBreed from './CreateBreed.vue'
import EditBreed from './EditBreed.vue'
import icons8 from './icons8.vue'

export default {
  name: 'Breeds',
  components:{
    Timeline,
    TimelineItem,
    AddBreed,
    EditBreed,
    icons8
  },
  data(){
    return{
      breeds: [],
      selected: null,
      breedData: null,
      showAddBreed: false,
      showEditBreed: false,
      // selectedBreedData: false,
      selectedBreedArt: false
    }
  },
  computed: {
    // a computed getter
    selectedBreed() {
      const brutart = this.breeds.find(x => x.key === this.selected)
      this.getBreedData(brutart)
      // this.selectedBreedData = brutart
      return brutart
    }
  },
  methods:{
    async loadBreeds(){
       this.breeds = []
      const timestamp = moment().format('x')
      const breedsQuery = await get(query(ref(getDatabase(), `/breeds/${store.state.user.data.uid}`), orderByChild('end'), startAt(timestamp)))
      breedsQuery.forEach(each=>{
        this.selected = this.selected || each.key
        const thisBreed = each.val()
        thisBreed.key = each.key
        this.breeds.push(thisBreed)
      })
    },
    tageBisZumSchlupf(breedEnd){
      let start = moment(new Date())
      let end = moment(breedEnd, 'x')
      return end.diff(start, 'days') 
    },
    formatDate(date){
      return moment(date, 'x').format("DD.MM.YYYY")
    },
    async getBreedData(brutart){
      const breedsRef = doc(getFirestore(), "brutarten", brutart.brutart)
      const docSnap = await getDoc(breedsRef)
      if (!docSnap.empty) {
        this.selectedBreedArt = docSnap.data()
      } else {
        store.dispatch("fetchAlert", {
          kind: 'danger',
          message: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es Später erneut.'
        })
      }
    },
    orderMyData(object){
      const compare = ( a, b )=>{
        if ( a.Dauer < b.Dauer ){
          return -1;
        }
        if ( a.Dauer > b.Dauer ){
          return 1;
        }
        return 0;
      }
      return Object.values(object).sort( compare )
    },
    returnDays(start, add){
      if(!start || !add){
        return 0
      }
      const theMoment = moment(start, 'x').add(add, 'days')
      return theMoment.diff(new Date(), 'days')
    }
  },
  mounted(){
    this.loadBreeds()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}
.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: #f8f8f8;
}
.big {
  font-size: 4rem;

}
</style>
