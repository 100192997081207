<template>
  <div class="alerts" v-if="alert.message">
    <b-alert dismissible :variant="alert.kind" @dismissed="resetAlert()" :show="alert.time" >
       {{alert.message}}
    </b-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { store } from '../store'

export default {
  name: 'Alert',
  data(){
    return{
      kind: 'danger',
      message: 'TEST'
    }
  },
  computed: {
    ...mapGetters([
      'alert',
    ])
  },
  methods:{
    resetAlert(){
      store.dispatch("fetchAlert", {
      kind: null,
      message: ''
    })
    }
  }
}
</script>

<style scoped>
  .alerts {
    position: absolute; 
    top: 10px; 
    z-index: 99999;
    width: 100%;
    padding: 20px;
  }
</style>
