<template>
  <div class="d-flex justify-content-center align-items-center" style="height: calc(100vh - 164px)">
    <h1>Hello {{this.$store.user}}</h1>
  </div>
</template>

<script>


export default {
  name: 'WebApp',
  data(){
    return{
      show: true,

    }
  },
  methods:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}

.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
</style>
