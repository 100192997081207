import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faUser,
  faPaperPlane,
  faArchive,
  faEgg,
  faArrowRightFromBracket,
  faPlus,
  faKiwiBird,
  faTemperatureHalf,
  faCalendarDays,
  faDroplet,
  faPenToSquare,
  faTrash
 } from '@fortawesome/free-solid-svg-icons'

library.add(
  faUser, 
  faPaperPlane, 
  faArchive, 
  faEgg, 
  faArrowRightFromBracket, 
  faPlus, 
  faKiwiBird, 
  faTemperatureHalf, 
  faCalendarDays, 
  faDroplet, 
  faPenToSquare,
  faTrash )



