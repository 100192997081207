<template>
  <div>
    <b-card :header="'Brut'+ title">
      <b-form @submit.prevent="onSubmit">
        <b-form-group
          id="input-group-1"
          label="Titel"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.title"
            type="text"
            placeholder="Titel"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Notizen"
          label-for="notes"
        >
          <b-form-textarea
            id="notes"
            v-model="form.notes"
            placeholder="Notizen ..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-button type="submit" class="w-100" variant="primary">Speichern</b-button>
        <b-button @click="$emit('closeEditBreed')" class="w-100 mt-3" variant="warning">Abbrechen</b-button>
        <b-button @click="deleteBreed()" class="w-100  mt-3" variant="danger">Löschen</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { getDatabase, ref, update, remove } from "firebase/database";
import {store} from '../../store'

export default {
  name: 'Breeds',
  props:{
    id: String,
    notes: String, 
    title: String
  },
  data(){
    return{
      form: {
        notes: null,
        title: null
      }
    }
  },
  computed: {
  },
  methods:{
    onSubmit(){
      let Data = {
        title: this.form.title || 'Kein Tietel',
        notiz: this.form.notes || '',
      }
      update(ref(getDatabase(), 'breeds/' + this.$store.state.user.data.uid + '/'+ this.id), Data)
      .then(()=>{
        const title = this.form.title
         store.dispatch("fetchAlert", {
          kind: 'success',
          time: 3,
          message: `Ihre Brut ${title} wurde erfolgreich bearbeitet.`
        })
        this.$emit('reloadBreed')
        this.$emit('closeEditBreed')
      })
      .catch(err=>{
         store.dispatch("fetchAlert", {
          kind: 'danger',
          message: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es Später erneut.'
        })
        console.log(err)
      })
    },
    deleteBreed(){
      remove(ref(getDatabase(), 'breeds/' + this.$store.state.user.data.uid + '/'+ this.id))
      this.$emit('reloadBreed')
      this.$emit('closeEditBreed')
    }
  },
  mounted(){
    this.form.notes = this.notes
    this.form.title = this.title
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}
.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: #f8f8f8;
}
.big {
  font-size: 4rem;

}
</style>
