<template>
  <div class="d-flex justify-content-center align-items-center" style="height: calc(100vh - 164px)">
    <div class="text-center">
    <p>Wilkommen, Bitte bestätigen Sie ihre E-Mail-Adresse.</p><br>
    <a href="#" @click="resend">E-Mail erneut senden</a>
    </div>
  </div>
</template>

<script>
import {getAuth, signOut, sendEmailVerification} from "firebase/auth"
import {store} from '../store'

export default {
  name: 'emailverification',
  data(){
    return{
      interval: null
    }
  },
  methods: {
    isSignedIn() {
    this.interval = setInterval(async () => {
      const user = getAuth()
      if (user.emailVerified) {
        signOut(getAuth())
        clearInterval(this.interval)
        this.$router.push('login')
      }
    }, 1000);
    },
    resend() {
      sendEmailVerification(getAuth().currentUser)
      .then(()=>{
        store.dispatch("fetchAlert", {
          kind: 'success',
          time: 3,
          message: 'E-Mail wurde versendet.'
        })
      })
      .catch(err=>{
        store.dispatch("fetchAlert", {
          kind: 'danger',
          time: 3,
          message: err
        })
      })
    }
  },
  mounted() {
    this.isSignedIn()
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background-color: #5cbda3;
  color: white;
  font-weight: 700;
}
.btn-primary{
  background-color: #5cbda3;
  border-color: #5cbda3;
    font-weight: 500;
}
.btn-primary:hover, .btn-primary:disabled, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
  background-color: #48947f;
  border-color: #48947f;
}
.btn-link{
  color: #5cbda3;
    font-weight: 500;
}
.btn-link:hover, .btn-link:disabled, .btn-link:not(:disabled):not(.disabled):active, .btn-link:not(:disabled):not(.disabled).active, .show > .btn-link.dropdown-toggle{
  color: #48947f;

}

.spaceBetween{
  display: flex; 
  justify-content: space-between;
}
</style>
