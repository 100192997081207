import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { store } from "./store";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import "./fontawsome"

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

onAuthStateChanged(auth, (user) => {
  console.log('user State Chenged', user)
  store.dispatch("fetchUser", user)
  new Vue({
    render: h => h(App),
    router,
    store
  }).$mount('#app')
})

Vue.component('font-awesome-icon', FontAwesomeIcon)

// app
